import { useSearchParams } from "react-router-dom";

export const useTasksFilter = () => {
  const [searchParams] = useSearchParams();

  let isFilterKeysExist = false;
  for (const key of searchParams.keys()) {
    if (key !== "name" && key !== "page") isFilterKeysExist = true;
  }

  let filters: { field: string; clause?: string; value: any }[] = [
    { field: "assigned_status", value: "3" },
    {
      field: "form_data__negotiator__negotiatorData__location__region",
      clause: "values_in",
      value: searchParams.getAll("region"),
    },
    {
      field:
        "form_data__customer_service__beneficiaryData__benificiaryServiceType__serviceType",
      clause: "values_in",
      value: searchParams.getAll("serviceType"),
    },
    {
      field: "form_data__negotiator__negotiatorData__location__city",
      clause: "values_in",
      value: searchParams.getAll("city"),
    },
    {
      field: "form_data__negotiator__negotiatorData__supportPackages__product",
      clause: "values_in",
      value: searchParams.getAll("product"),
    },
    {
      field: "status",
      clause: "values_in",
      value: searchParams.getAll("status"),
    },
  ];

  filters = filters.filter((el) => el.value && el.value?.length !== 0);

  if (searchParams.get("name")) {
    if (isNaN(searchParams.get("name") as unknown as number)) {
      filters.push({
        field: "form_data__negotiator__negotiatorData__personalData__name",
        clause: "icontains",
        value: searchParams.get("name"),
      });
    } else {
      filters.push({
        field: "beneficiary__external_id",
        clause: "icontains",
        value: Number(searchParams.get("name")),
      });
    }
  }
  if (searchParams.get("mobileNumber")) {
    filters.push({
      field:
        "form_data__negotiator__negotiatorData__personalData__mobileNumber",
      clause: "icontains",
      value: searchParams.get("mobileNumber"),
    });
  }

  return {
    isFilterKeysExist,
    filters,
  };
};
