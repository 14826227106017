import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BENEFICIARIES_FROM_TASKS } from "@/shared/graphql/queries/tasks";
import { useBeneficiariesFilter } from "./useBeneficiariesFilter";
import { BeneficiariesListNegotiator } from "./BeneficiariesList/BeneficiariesListNegotiator";
import { PaginationComponent } from "./PaginationComponent";
import { Loading } from "@/components/Loading";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { useSearchParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { t } from "i18next";
import { ReAssignRenTask } from "./ReassignTasks/ReassignNegotiatorTasks";

export const NegotiatorBeneficiaries = () => {
  const [searchParams] = useSearchParams({ page: "1" });
  const [pageLimit, setPageLimit] = useState<number>(10);
  const { filters, orderBy } = useBeneficiariesFilter();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const { data, refetch, loading } = useQuery(GET_BENEFICIARIES_FROM_TASKS, {
    variables: {
      role: userInfo?.role?.queryVar,
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      filters,
      orderBy,
    },
  });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {selectedTasks.length > 0 && (
            <Box sx={{ display: "flex", px: 2, alignItems: "center" }}>
              <Typography color="text.secondary" sx={{ mr: 2.4 }}>
                {t("selected")} {selectedTasks?.length}
              </Typography>
              <ReAssignRenTask
                taskIds={selectedTasks}
                refetch={refetch}
                setSelectedTasks={setSelectedTasks}
              />
            </Box>
          )}
          <BeneficiariesListNegotiator
            tasks={data?.tasks?.data}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
          <Divider />
          <PaginationComponent
            count={data?.tasks?.count}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </>
      )}
    </>
  );
};
