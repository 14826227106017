import { FC, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import Person2Icon from "@mui/icons-material/Person2";
import { styles } from "./Appbar.style";
import { useAuth } from "react-oidc-context";
import { AppbarNavLink } from "./AppbarNavLink";
import { useTranslation } from "react-i18next";
import {
  customerServiceLinks,
  negotiatorLinks,
  projectManagerLinks,
} from "@/shared/contstants";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { RolesDropDown } from "./RolesDropDown";
import { Notifications } from "@/modules/app/negotiator/Tasks/Notifications/Notifications";

export const Appbar: FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let appbarLinks: { text: string; to: string }[] = [];
  if (userInfo?.role?.key === 2) {
    appbarLinks = [...appbarLinks, ...projectManagerLinks];
  }
  if (userInfo?.role?.key === 4) {
    appbarLinks = [...appbarLinks, ...negotiatorLinks];
  }

  if (userInfo?.role?.key === 3) {
    appbarLinks = [...appbarLinks, ...customerServiceLinks];
  }

  return (
    <MuiAppBar
      data-testid="appbar"
      sx={styles}
      position="sticky"
      color="transparent"
      elevation={0}
    >
      <Toolbar
        sx={{
          pr: "24px",
          display: "flex",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Box display="flex">
          <Typography fontWeight="bold" variant="h6" sx={{ mr: 6.4 }}>
            {t("ren")}
          </Typography>
          <Box display="flex" alignItems="center">
            {appbarLinks.map((el: { to: string; text: string }) => (
              <AppbarNavLink key={el.to} to={el.to}>
                {t(el.text)}
              </AppbarNavLink>
            ))}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Notifications />
          <Box>
            <Typography fontWeight="bold">
              {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
            <Box display="flex">
              <RolesDropDown />
            </Box>
          </Box>
          <Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar>
                  <Person2Icon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 40,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => void auth.removeUser()}>
                <ListItemIcon>
                  <Logout color="error" fontSize="small" />
                </ListItemIcon>
                <Typography color="error">{t("logout")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};
