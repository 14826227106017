import React, { useState } from "react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { Box, Button, Grid, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import { GeometryField } from "@/components/RJSF/fields";
import BordersAndLengthsField from "@/components/RJSF/fields/BordersAndLengths/BordersAndLengthsField";
import UpDownWidget from "@/components/RJSF/widgets/UpDownWidget/UpDownWidget";
import { useQuery } from "@apollo/client";
import { GET_SCHEMA_BY_KEY, GET_OFFERS_LOGS } from "@/shared/graphql";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { Loading } from "@/components/Loading";

export const ViewOffer = () => {
  const [formData, setFormData] = useState<any>({});
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const { t } = useTranslation();
  const formKey = "internal_offer_schema";
  const { offerId, taskId, logId } = useParams();

  // Get the schema
  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = JSON.parse(
        JSON.stringify(res.schemas.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;

      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });

  // Get the offer data from logs
  const { loading: logsLoading } = useQuery(GET_OFFERS_LOGS, {
    variables: { taskId, offerId, pk: logId },
    onCompleted: (res) => {
      const formHistory = res?.requestAudits?.data?.at(0)?.data;

      if (formHistory) {
        setFormData(formHistory);
      }
    },
  });

  if (schemaLoading || logsLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "fixed",
          top: 120,
          zIndex: 1000,
          padding: 1,
        }}
      >
        <NavLink to={`../tasks/${taskId}`}>
          <EastIcon fontSize="medium" sx={{ mr: 1 }} />
        </NavLink>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("View Offer")} {offerId}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          p: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          sx={{
            width: "70%",
          }}
        >
          <Form
            schema={formSchema || {}}
            uiSchema={uiSchema}
            validator={validator}
            showErrorList={false}
            formData={formData}
            experimental_defaultFormStateBehavior={{
              allOf: "populateDefaults",
            }}
            noHtml5Validate
            widgets={{
              FileWidget,
              YesNoWidget,
              TextWidget,
              SelectWidget,
              DateWidget: DatePickerWidget,
              UpDownWidget,
            }}
            fields={{
              FundCalculatorWidget: FundCalculatorWidget as any,
              GeometryField: GeometryField as any,
              BordersAndLengthsField: BordersAndLengthsField as any,
            }}
            templates={{ ObjectFieldTemplate }}
          >
            <Button variant="contained" sx={{ mt: 2, mr: 1 }} disabled={true}>
              {t("save and complete later")}
            </Button>
            <Button variant="contained" sx={{ mt: 2 }} disabled={true}>
              {t("save")}
            </Button>
          </Form>
        </Grid>
      </Box>
    </Box>
  );
};
