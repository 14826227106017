import { useState } from "react";
import { Form } from "@rjsf/mui";
import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { useMutation, useQuery } from "@apollo/client";
import { graphqlErrorHandler } from "@/shared/utils";
import { toast } from "react-hot-toast";
import { GET_SCHEMA_BY_KEY } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { CREATE_EXTERNAL_BENEFICIARY } from "@/shared/graphql/mutations/beneficiary";

interface ExternalBeneficiaryProps {
  open: boolean;
  onClose: () => void;
}

export const ExternalBeneficiary = ({
  open,
  onClose,
}: ExternalBeneficiaryProps) => {
  const { t } = useTranslation();
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});

  const [formData, setFormData] = useState({
    personalData: {},
    professionalData: {},
    financialData: {},
    personalRealEstateData: {},
    supportPackages: {},
  });
  const formKey = "add-external-beneficiary";
  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: "add-external-beneficiary" },
    onCompleted: async (res) => {
      const schema = res?.schemas?.data?.at(0)?.jsonSchema;
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;

      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });

  const [createBeneficiary, { loading: mutationLoading }] = useMutation(
    CREATE_EXTERNAL_BENEFICIARY,
    {
      onCompleted: (data) => {
        if (data?.createBeneficiaryMutation?.beneficiary?.id) {
          toast.success(t("تمت إضافة المستفيد بنجاح"));
          setFormData({
            personalData: {},
            professionalData: {},
            financialData: {},
            personalRealEstateData: {},
            supportPackages: {},
          });
          onClose();
        } else {
          toast.error(t("حدث خطأ أثناء إضافة المستفيد"));
        }
      },
      onError: (error) => {
        graphqlErrorHandler(error);
        toast.error(t("حدث خطأ أثناء إضافة المستفيد"));
      },
    }
  );

  const onSubmit = async (data: IChangeEvent) => {
    try {
      await createBeneficiary({
        variables: {
          beneficiaryInput: {
            formSchema: formKey,
            formData: JSON.stringify(data.formData),
          },
        },
      });
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error(t("حدث خطأ أثناء إضافة المستفيد"));
    }
  };

  if (schemaLoading) return <Loading />;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            bgcolor: "background.paper",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            {t("إضافة مستفيد خارجي")}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "inherit",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Form
            schema={formSchema}
            uiSchema={uiSchema}
            validator={validator}
            formData={formData}
            onChange={({ formData }) => setFormData(formData)}
            onSubmit={onSubmit}
            noHtml5Validate
            showErrorList={false}
            transformErrors={(errors) => {
              return errors.map((err) => {
                if (err.name === "required") {
                  return { ...err, message: "هذا الحقل مطلوب" };
                }
                if (err.name === "pattern") {
                  return {
                    ...err,
                    message:
                      "يجب ان يبدأ رقم التليفون بـ 5 و الا يقل عن 8 ارقام",
                  };
                }
                return err;
              });
            }}
            widgets={{
              TextWidget,
              YesNoWidget,
              SelectWidget,
            }}
            templates={{ ObjectFieldTemplate }}
          >
            <DialogActions sx={{ px: 0, pb: 0, mt: 2 }}>
              <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
                {t("إلغاء")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={mutationLoading}
              >
                {t("إضافة المستفيد")}
              </Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
