export const insertRegionsInFormSchema = (originalSchema, allRegions) => {
  const schema = JSON.parse(JSON.stringify(originalSchema) || "{}");
  if (schema?.definitions?.locationPreferences?.properties?.preferredRegion) {
    schema.definitions.locationPreferences.properties.preferredRegion.enum =
      allRegions?.map((region) => region.sourceProperties.region_name);
  }

  return schema;
};

export const insertCitiesInFormSchema = (originalSchema, citiesData) => {
  const schema = JSON.parse(JSON.stringify(originalSchema) || "{}");
  if (schema?.definitions?.locationPreferences?.properties?.preferredCity) {
    schema.definitions.locationPreferences.properties.preferredCity.enum =
      citiesData?.map((city) => city.sourceProperties.city_name);
  }

  return schema;
};

export const insertZonesInFormSchema = (originalSchema, allZones) => {
  const schema = JSON.parse(JSON.stringify(originalSchema) || "{}");
  if (schema?.definitions?.locationPreferences?.properties?.mainDivision) {
    if (allZones.length) {
      schema.definitions.locationPreferences.properties.mainDivision.items.enum =
        allZones;
      schema.definitions.locationPreferences.properties.mainDivision.items.enumNames =
        allZones.map((zone) => zone.label);
    } else {
      delete schema.definitions.locationPreferences.properties.mainDivision
        .items.enum;
      delete schema.definitions.locationPreferences.properties.mainDivision
        .items.enumNames;
    }
  }

  return schema;
};

export const insertDistrictsInFormSchema = (originalSchema, allDistricts) => {
  const schema = JSON.parse(JSON.stringify(originalSchema) || "{}");
  if (schema?.definitions?.locationPreferences?.properties?.district) {
    if (allDistricts?.length) {
      schema.definitions.locationPreferences.properties.district.items.enum =
        allDistricts;
      schema.definitions.locationPreferences.properties.district.items.enumNames =
        allDistricts.map((district) => district.label);
    } else {
      delete schema.definitions.locationPreferences.properties.district.items
        .enum;
      delete schema.definitions.locationPreferences.properties.district.items
        .enumNames;
    }
  }

  return schema;
};
