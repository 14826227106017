import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Card, Typography, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { BeneficiariesFilters } from "./BeneficiariesFilters/BeneficiariesFilters";
import { BeneficiaryTabs } from "./BeneficiaryTabs";
import { ExternalBeneficiary } from "./ExternalBeneficiary";

export const Beneficiaries = () => {
  const { t } = useTranslation();
  const [isExternalBeneficiaryModalOpen, setIsExternalBeneficiaryModalOpen] =
    useState(false);

  const handleOpenExternalBeneficiaryModal = () => {
    setIsExternalBeneficiaryModalOpen(true);
  };

  const handleCloseExternalBeneficiaryModal = () => {
    setIsExternalBeneficiaryModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography fontWeight="bold" variant="h5">
          {t("Beneficiaries")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenExternalBeneficiaryModal}
        >
          {t("Add External Beneficiary")}
        </Button>
      </Box>
      <BeneficiariesFilters />
      <Card variant="outlined">
        <BeneficiaryTabs />
        <Outlet />
      </Card>

      <ExternalBeneficiary
        open={isExternalBeneficiaryModalOpen}
        onClose={handleCloseExternalBeneficiaryModal}
      />
    </>
  );
};
