import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { GET_ALL_OFFERS } from "@/shared/graphql";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { OffersTable } from "./OffersTable";
import { OfferDetails } from "./OfferDetails";
import { OffersMap } from "./OffersMap";
import { useState } from "react";
import { graphqlErrorHandler } from "@/shared/utils";

export const Offers = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const [selectedOffer, setSelectedOffer] = useState("");

  const { taskId } = useParams();
  const handleClick = () => {
    if (taskId) {
      navigate(`/app/negotiator/tasks/${taskId}/add-offer`);
    } else {
      console.error("Task ID is missing from the URL");
    }
  };
  const handleRowClick = (offer) => {
    setSelectedOffer(offer);
  };
  const {
    data,
    refetch: refetchOffers,
    loading,
    error,
  } = useQuery(GET_ALL_OFFERS, {
    variables: {
      taskId: taskId,
    },
    onCompleted: (data) => {
      const offers = data?.offers?.data;
      setSelectedOffer(offers?.at(0)?.id);
    },
    onError: (error) => {
      graphqlErrorHandler(error);
    },
  });

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  };

  const completedOffers = data?.offers?.data?.at(0)?.completedOffers;
  const uncompletedOffers = data?.offers?.data?.at(0)?.uncompletedOffers;
  const externalOffers = data?.offers?.data?.at(0)?.externalOffers;
  const offersBoundries = data?.offers?.boundaries;
  const offersGeometries = data?.offers?.geometries;

  if (loading) return <Loading />;
  if (error) return <></>;

  return (
    <Box sx={{ py: 3, textAlign: "center" }}>
      <Grid item xs={12} lg={12}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            textAlign: "start",
            p: 2,
          }}
        >
          {t("Offers")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="standard"
            sx={{ borderBottom: "1px solid #ddd" }}
          >
            <Tab
              label={t("Completed Offers")}
              sx={{ fontSize: "12px", fontWeight: 700 }}
            />
            <Tab
              label={t("Offers Project")}
              sx={{ fontSize: "12px", fontWeight: 700 }}
            />
            <Tab
              label={t("External Offers")}
              sx={{ fontSize: "12px", fontWeight: 700 }}
            />
          </Tabs>
          {completedOffers.length > 0 && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ px: 1.5, mb: 2 }}
              onClick={handleClick}
            >
              {t("Add Offer")}
            </Button>
          )}
        </Box>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} lg={7.5}>
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <OfferDetails
                  offer={completedOffers.find(
                    (offer) => offer.id === selectedOffer
                  )}
                  refetchOffers={refetchOffers}
                />
                <OffersTable
                  offers={completedOffers || []}
                  refetchOffers={refetchOffers}
                  onRowClick={handleRowClick}
                  selectedOffer={selectedOffer}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <OffersTable
              offers={uncompletedOffers || []}
              refetchOffers={refetchOffers}
              onRowClick={handleRowClick}
              selectedOffer={selectedOffer}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <OffersTable
              offers={externalOffers || []}
              refetchOffers={refetchOffers}
              onRowClick={handleRowClick}
              selectedOffer={selectedOffer}
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12} lg={4.5}>
          <OffersMap
            offersBoundries={offersBoundries}
            offersGeometries={offersGeometries}
            selectedOffer={selectedOffer}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
