import {
  Button,
  Card,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  CREATE_FAVORITE_OFFER,
  DELETE_OFFER_FROM_FAVOURITES,
} from "@/shared/graphql/mutations";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";
import { OffersEmptyState } from "@/components/OffersEmptyState";

export const OffersTable = ({
  offers,
  refetchOffers,
  onRowClick,
  selectedOffer,
}) => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const navigate = useNavigate();

  const [createFavoriteOffer, { loading: addFavoriteMutationLoading }] =
    useMutation(CREATE_FAVORITE_OFFER);
  const [deleteOffer, { loading: removeMutationLoading }] = useMutation(
    DELETE_OFFER_FROM_FAVOURITES
  );
  const addToFavorites = (offerId) => {
    createFavoriteOffer({
      variables: {
        offerInput: { taskId, offerId },
      },
    })
      .then(() => {
        refetchOffers();
        toast.success(t("Offer added to favorites"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  const handleClick = () => {
    if (taskId) {
      navigate(`/app/negotiator/tasks/${taskId}/add-offer`);
    } else {
      console.error("Task ID is missing from the URL");
    }
  };
  if (!offers || offers.length === 0) {
    return (
      <>
        <OffersEmptyState />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ py: 1, px: 8 }}
          onClick={handleClick}
        >
          {t("Add Offer")}
        </Button>
      </>
    );
  }
  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };
  const handleEditOffer = (completedOfferId, renOfferId, offerType) => {
    const offerId = offerType === "completed" ? completedOfferId : renOfferId;
    navigate(
      `/app/negotiator/tasks/${taskId}/edit-offer/${offerId}?offerLocation=${offerType}`
    );
  };

  const onDelete = (offerId) => {
    deleteOffer({
      variables: {
        favoriteOfferId: Number(offerId),
      },
    })
      .then(() => {
        toast.success(t("Offer Deleted Successfully"));
        refetchOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  return (
    <Card variant="outlined">
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-label="Offers Table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Offer Id")}</TableCell>
              <TableCell>{t("Property type")}</TableCell>
              <TableCell>{t("Offer type")}</TableCell>
              <TableCell>{t("Address")}</TableCell>
              <TableCell>{t("Price")}</TableCell>
              <TableCell>{t("Offer Status")}</TableCell>
              <TableCell>{t("Number of negotiators")}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((offer, index) => (
              <TableRow
                key={index}
                onClick={() => onRowClick(offer.id)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedOffer === offer?.id ? "#f0f0f0" : "transparent",
                }}
              >
                <TableCell>{offer?.id}</TableCell>
                <TableCell>
                  {offer?.offerData?.establishmentType ?? "---"}
                </TableCell>
                <TableCell>
                  {offer?.offerData?.offerType?.map((item, index) => (
                    <div key={index}>{item ?? "---"}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {formatAddress(offer?.offerData?.address) ?? "---"}
                </TableCell>
                <TableCell>
                  {offer?.offerData?.realEstatePrice ?? "---"}
                </TableCell>

                <TableCell>
                  <Chip label={t(offer?.status)} />
                </TableCell>
                <TableCell>{offer?.negotiators?.count}</TableCell>
                <TableCell>
                  {offer?.recommended && (
                    <Chip
                      color="success"
                      size="small"
                      icon={<CheckCircleOutlineOutlinedIcon />}
                      label={t("Recommended Offer")}
                    />
                  )}
                </TableCell>
                <TableCell sx={{ display: "flex", minHeight: "60px" }}>
                  {offer?.renOfferId && (
                    <IconButton
                      onClick={() =>
                        handleEditOffer(
                          offer?.id,
                          offer?.renOfferId,
                          offer?.offerType
                        )
                      }
                    >
                      <EditIcon sx={{ color: "#8C8C8C" }} fontSize="medium" />
                    </IconButton>
                  )}
                  {offer?.offerType === "completed" &&
                    (offer?.favoriteOfferId ? (
                      <IconButton
                        onClick={() => onDelete(offer?.favoriteOfferId)}
                        disabled={addFavoriteMutationLoading}
                      >
                        <StarRoundedIcon
                          sx={{
                            color: "#FFD700",
                          }}
                          fill="currentColor"
                          fontSize="large"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => addToFavorites(offer?.id)}
                        disabled={removeMutationLoading}
                      >
                        <StarBorderRoundedIcon
                          sx={{
                            color: "#8C8C8C",
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
