import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_OFFERS_LOGS } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

interface OffersLogsProps {
  taskId: string | undefined;
}

export const OffersLogs = ({ taskId }: OffersLogsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: logsData, loading: logsLoading } = useQuery(GET_OFFERS_LOGS, {
    variables: {
      taskId: Number(taskId),
      pageInfo: {
        orderBy: "-created",
      },
    },
  });

  const hasLogs = logsData?.requestAudits?.data?.length > 0;
  const statusMapper = {
    1: t("Pending Send Offers"),
    2: t("Sent"),
    3: t("Accepted"),
    4: t("RejectedOffer"),
  };

  return (
    <Card variant="outlined" sx={{ p: 2, mt: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        {t("Offers Logs")}
      </Typography>
      <Box sx={{ maxHeight: 400, overflow: "auto" }}>
        {logsLoading ? (
          <Loading />
        ) : !hasLogs ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              py: 8,
              textAlign: "center",
            }}
          >
            <DescriptionOutlinedIcon
              sx={{ fontSize: 64, color: "text.secondary", mb: 0 }}
            />
            <Typography variant="h6" color="text.secondary">
              {t("No logs available")}
            </Typography>
            <Typography color="text.secondary">
              {t("There are no offer logs to display at the moment")}
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Date and Time")}</TableCell>
                  <TableCell>{t("Offer Numbers")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logsData?.requestAudits?.data?.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>
                      {new Date(log.created).toLocaleDateString("en-UK")} -{" "}
                      {new Date(log.created).toLocaleTimeString("en-SA", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      {log.offers?.map((offerId, index) => (
                        <Link
                          key={offerId}
                          component="button"
                          onClick={() =>
                            log.hasData &&
                            navigate(
                              `/app/negotiator/tasks/${taskId}/view-offer/${offerId}/${log.id}`
                            )
                          }
                          sx={{
                            textDecoration: log.hasData ? "underline" : "none",
                            cursor: log.hasData ? "pointer" : "default",
                            mr: 1,
                            color: log.hasData ? "inherit" : "text.disabled",
                          }}
                        >
                          {offerId}
                          {index < log.offers.length - 1 ? " ," : ""}
                        </Link>
                      ))}
                    </TableCell>
                    <TableCell>
                      {t(statusMapper[Object.keys(log.status)[0]])}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Card>
  );
};
