import { gql } from "@apollo/client";

export const GET_BENEFICIARIES = gql`
  query GetBeneficiaries(
    $filters: [DjangoFilterInput]!
    $limit: BoundedInt!
    $offset: BoundedInt!
    $orderBy: String!
  ) {
    beneficiaries(
      pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }
      filters: $filters
    ) {
      data {
        id
        code
        name
        externalId
        taskStatus
        assignedTaskStatus
        beneficiaryData {
          age
          region
          mobile
          job
          city
          product
          familyMember
          salary
          salaryBank
          avgFund
          destination
        }
      }
      count
    }
  }
`;

export const GET_BENEFICIARIES_FILTER_OPTIONS = gql`
  query {
    beneficiaryFilterOptions {
      job
      region
      city
      product
      salaryBank
      taskStatus
      serviceType
    }
  }
`;
