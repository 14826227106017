import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($pageInfo: PageInfo) {
    notifications(pageInfo: $pageInfo) {
      data {
        taskId
        description
        userRole
        verb
        unread
        timesince
        level
        id
        data
        actor
      }
      count
      unreadCount
    }
  }
`;
