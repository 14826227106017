import { gql } from "@apollo/client";

export const REJECT_DEAL = gql`
  mutation RejectDeal($dealInput: RejectDealInputType!) {
    rejectDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;
export const REJECT_AGREEMENT = gql`
  mutation RejectAgreement($agreementInput: RejectAgreementInputTpe!) {
    rejectAgreementMutation(agreementInput: $agreementInput) {
      agreement {
        id
      }
    }
  }
`;

export const FINISH_DEAL = gql`
  mutation finishDeal($dealInput: FinishDealInputType!) {
    finishDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;
export const FINISH_AGREEMENT = gql`
  mutation finishAgreement($agreementInput: FinishAgreementInputTpe!) {
    finishAgreementMutation(agreementInput: $agreementInput) {
      agreement {
        id
      }
    }
  }
`;

export const CREATE_DEAL = gql`
  mutation createDeal($dealInput: CreateDealInputType!) {
    createDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;
export const CREATE_AGREEMENT = gql`
  mutation createAgreement($agreementInput: CreateAgreementInputTpe!) {
    createAgreementMutation(agreementInput: $agreementInput) {
      agreement {
        id
      }
    }
  }
`;
export const REPLACE_DEAL = gql`
  mutation UpdateDeal($favoriteOfferInput: ReplaceDealFavoriteOfferInputType!) {
    replaceDealFavoriteOffer(favoriteOfferInput: $favoriteOfferInput) {
      deal {
        id
      }
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal($dealInput: UpdateDealInputType!) {
    updateDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;
export const UPDATE_AGREEMENT = gql`
  mutation UpdateAgreement($agreementInput: UpdateAgreementInputTpe!) {
    updateAgreementMutation(agreementInput: $agreementInput) {
      agreement {
        id
      }
    }
  }
`;
