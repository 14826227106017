import { gql } from "@apollo/client";

export const GET_ALL_FAVOURITE_OFFERS = gql`
  query favoriteOffers($taskId: Int!) {
    favoriteOffers(taskId: $taskId) {
      count
      isLinkExpired
      shareOffers
      data {
        reserved
        hasDeal
        id
        offerId
        taskId
        offer {
          status
          offerData {
            establishmentType
            address
            realEstatePrice
            offerType
          }
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          id
          geometry
          favoriteOfferId
          data
        }
      }
    }
  }
`;
export const GET_OFFERS_LOGS = gql`
  query requestAudits(
    $taskId: Int!
    $offerId: Int
    $pk: Int
    $pageInfo: PageInfo
  ) {
    requestAudits(
      taskId: $taskId
      offerId: $offerId
      pk: $pk
      pageInfo: $pageInfo
    ) {
      count
      data {
        created
        createdBy {
          firstName
          lastName
        }
        hasData
        data
        id
        modified
        offers
        status
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query getAllOffers($taskId: Int!) {
    offers(taskId: $taskId) {
      boundaries
      geometries
      data {
        completedOffers {
          data
          favoriteOfferId
          geometry
          id
          offerType
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          offerData {
            address
            establishmentType
            images
            offerBathroom
            offerArea
            offerRoom
            offerType
            realEstatePrice
          }
          renOfferId
          status
        }
        externalOffers {
          data
          favoriteOfferId
          geometry
          id
          offerType
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          offerData {
            address
            establishmentType
            images
            offerArea
            offerBathroom
            offerRoom
            offerType
            realEstatePrice
          }
          renOfferId
          status
        }
        uncompletedOffers {
          data
          favoriteOfferId
          geometry
          id
          offerType
          negotiators {
            count
            data {
              lastName
              firstName
            }
          }
          offerData {
            address
            establishmentType
            images
            offerArea
            offerBathroom
            offerRoom
            offerType
            realEstatePrice
          }
          renOfferId
          status
        }
      }
    }
  }
`;

export const GET_EXTERNAL_OFFER_DATA = gql`
  query getExternalOfferData($pk: Int, $taskId: Int!) {
    offers(pk: $pk, taskId: $taskId) {
      data {
        completedOffers {
          data
          renOfferId
        }
        externalOffers {
          data
          renOfferId
        }
        uncompletedOffers {
          data
          renOfferId
        }
      }
    }
  }
`;
export const GET_INTERNAL_OFFER_DATA = gql`
  query getInternalOfferData($pk: Int) {
    internalOffers(pk: $pk) {
      count
      data {
        data
      }
    }
  }
`;
export const GET_COORDINATES_DATA = gql`
  query offerLocationData($longitude: Float!, $latitude: Float!) {
    offerLocationData(longitude: $longitude, latitude: $latitude) {
      data
    }
  }
`;
