import { useState } from "react";
import { Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  BeneficiaryForm,
  ClientPreferencesLevel1,
  ClientPreferencesLevel2,
  Deal,
  FavouriteOffers,
  Offers,
} from "./steps";
import { TaskSidebarButton } from "./TaskSidebarButton";
import { RegionRecommendation } from "./steps/RegionRecommendation/RegionRecommendation";
import { DistrictRecommendations } from "./steps/DistrictRecommendations/DistrictRecommendations";
import { StatusChip } from "@/components/StatusChip";
import { GET_SIGNLE_TASK } from "@/shared/graphql";
import EastIcon from "@mui/icons-material/East";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { isNegotiator, isProjectManager } from "@/shared/utils/mappers";
import { NavLink } from "react-router-dom";

const steps = [
  {
    label: "Beneficiary data",
    value: "Beneficiary data",
    step: 1,
  },
  {
    label: "Region recommendation",
    value: "Region recommendation",
    step: 3,
  },
  {
    label: "client preferences level2",
    value: "client preferences level2",
    step: 4,
  },
  {
    label: "District recommendation",
    value: "District recommendation",
    step: 5,
  },
  {
    label: "Offers",
    value: "Offers",
    step: 6,
  },
  {
    label: "Favourite offers",
    value: "Favourite offers",
    step: 7,
  },
  {
    label: "Deal",
    value: "Deal",
    step: 8,
  },
];

export const Task = () => {
  const { taskId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const {
    data: taskRes,
    loading,
    refetch: refetchTask,
  } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
  });

  const canSubmit = userInfo?.isSuperuser || isNegotiator(userInfo);
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);
  const { orderId } = useParams();
  const handleSelectTab = (value) => {
    setSelectedStep(value);
  };

  if (loading) return <Loading />;
  const task = taskRes?.tasks?.data?.at(0);
  const customerPhone =
    task?.formData?.negotiator?.negotiatorData?.personalData?.mobileNumber;

  const handleWhatsAppClick = () => {
    if (customerPhone) {
      // Format number to international format (assuming Saudi number)
      const formattedNumber = `966${customerPhone.replace(/^0+/, "")}`;
      window.open(`https://wa.me/${formattedNumber}`, "_blank");
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        pb={1}
        sx={{ borderBottom: "1px solid #e3e3e3" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NavLink
            to={
              isProjectManager(userInfo) ? `../orders/${orderId}` : "../tasks"
            }
          >
            <EastIcon fontSize="medium" sx={{ mr: 1 }} />
          </NavLink>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("task")} {taskId}#
          </Typography>
        </Box>

        <Box>
          <Box mb={1} textAlign="right">
            <StatusChip status={task?.status} canSubmit={canSubmit} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.8}>
          <Box sx={{ position: "sticky", top: 100 }}>
            {steps?.map((step) => (
              <TaskSidebarButton
                active={selectedStep === step.step}
                onClick={() => handleSelectTab(step.step)}
              >
                {t(step.label)}
              </TaskSidebarButton>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={10.2}>
          {selectedStep === 1 && (
            <BeneficiaryForm
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 2 && (
            <ClientPreferencesLevel1
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 3 && <RegionRecommendation />}
          {selectedStep === 4 && (
            <ClientPreferencesLevel2
              canSubmit={canSubmit}
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 5 && <DistrictRecommendations />}

          {selectedStep === 6 && <Offers />}
          {selectedStep === 7 && (
            <FavouriteOffers
              refetchTask={refetchTask}
              setSelectedStep={setSelectedStep}
            />
          )}
          {selectedStep === 8 && <Deal canSubmit={canSubmit} />}
        </Grid>
      </Grid>
      <Tooltip title={t("Chat with customer")} placement="right">
        <IconButton
          onClick={handleWhatsAppClick}
          sx={{
            position: "fixed",
            bottom: "2rem",
            left: "2rem",
            backgroundColor: "#25D366",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#25D366",
            },
            width: 56,
            height: 56,
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          <WhatsAppIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
