import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import BeneficiaryOffers from "./BeneficiaryOffers/BeneficiaryOffers";
import BeneficiaryProfile from "./BeneficiaryProfile/BeneficiaryProfile";
import { useTranslation } from "react-i18next";
import { GET_BENEFECIARY_OFFERS } from "@/shared/graphql/queries/externalBenefeciary";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import OfferSuccess from "./BeneficiaryOffers/OfferSuccess";
import OffersPending from "./BeneficiaryOffers/OffersPending";

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

const Beneficiary = () => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };
  const { data, loading } = useQuery(GET_BENEFECIARY_OFFERS);

  const beneficiaryData = data?.beneficiaryOffers?.beneficiaryData;
  const beneficiaryOffers = data?.beneficiaryOffers?.offers;
  const taskId = data?.beneficiaryOffers?.task?.id;
  const timeElapsed = data?.beneficiaryOffers?.timeElapsed;
  const isLinkExpired = data?.beneficiaryOffers?.isLinkExpired;
  const status = data?.beneficiaryOffers?.request?.status?.key;
  const submitted = status === 3;
  const pendingOffers = status === 1;

  const formatTimeElapsed = (timeStr) => {
    if (!timeStr) return "";

    const parts = timeStr.split(", ");
    if (parts.length !== 2) return timeStr;

    const days = parts[0];
    const time = parts[1].split(".")[0];
    const [hours, minutes] = time.split(":");

    return t(
      "Link will expire after : {{days}} days, {{hours}} hours, {{minutes}} minutes",
      {
        days: days.split(" ")[0],
        hours,
        minutes,
      }
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (submitted) {
    return <OfferSuccess />;
  }

  if (pendingOffers) {
    return <OffersPending />;
  }
  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{ borderBottom: "1px solid #ddd", mb: 2 }}
      >
        <Tab label={t("Offers")} />
        <Tab label={t("Profile")} />
      </Tabs>
      {timeElapsed && !isLinkExpired && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 1,
            bgcolor: "#fff",
            fontWeight: "bold",
          }}
        >
          <Typography
            color="text.primary"
            sx={{
              fontSize: "14px",
              textAlign: "start",
            }}
          >
            {formatTimeElapsed(timeElapsed)}
          </Typography>
        </Box>
      )}
      <TabPanel value={tabIndex} index={0}>
        <BeneficiaryOffers
          beneficiaryOffers={beneficiaryOffers}
          taskId={taskId}
          isLinkExpired={isLinkExpired}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <BeneficiaryProfile beneficiaryData={beneficiaryData} />
      </TabPanel>
    </Box>
  );
};

export default Beneficiary;
